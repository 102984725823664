<template>
    
    <div class="relative">
         <!-- Action Buttons -->
        

       
            <tva></tva>
     
            <price></price>
       
         
       
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,
} from 'bootstrap-vue'
    import Tva from './Tva.vue'
    import Price from './PriceComponent.vue'
   
    
    export default {
        components : { 
            Tva , Price, BCard, BButton,Ripple
        },
        directives: {
            Ripple,
            'b-toggle': VBToggle,
        },
        mounted() {
            this.calcHeight()
        },
        data(){
            return {
                visible : this.$store.state.toggleCatalogueOuvrage,
                pattern : null , 
                result : {} ,
                zones : this.$store.state.zones ,
                zone_actif : this.$store.state.zone_actif , 
                nbResult : 1 , 
                newHeight : 0 , 
            }
        },
        computed : {
            
        },
        methods: {
            focusSearch(){
                this.pattern = ""
                this.$refs.engine.focus()
            },
            calcHeight(){
                //this.newHeight =  this.$refs.module_search.clientHeight - 140 + 'px' ;
                this.newHeight =  240 + 'px' ;
            },
            toggle(){
                this.$store.state.display.catalogue_ouvrage = !this.$store.state.display.catalogue_ouvrage
            },
            addOuvrage(id , famille ){
                this.$store.commit("ADD_OUVRAGE" ,  { id , famille } )
                //this.$store.commit("ADD_OUVRAGE" , { id , famille } )
            },
            addFamille(id){
               
                this.$store.commit("ADD_FAMILLE" ,  id  )
                //this.$store.commit("ADD_OUVRAGE" , { id , famille } )
            },
            search(event){
                axios.post("/admin/ajax_search/", { query : this.pattern } )
                .then((response)=>{
                    this.result = response.data
                    this.nbResult = Object.keys(response.data).length  ; 
                })
            },
            setActiveZone(zoneId){
                this.$store.commit("CHANGE_ZONE" , zoneId)
                this.zone_actif = zoneId
            },
         
        },
        created(){
           
          
        }
    };
</script>

<style>
    .result_row_search{
        background : #05374b ; 
        color : #FFF;
    }

    .result{
       
        overflow: scroll;
    }

</style>

<template>
    <div class="block_ouvrage">
        <div id="" class=' ' data-famille="" v-if="type ==  'ouvrage' " > 
            <div class="ouvrage_action">
                <feather-icon class="edit_ouvrage" icon="Edit3Icon" size="17"  @click="update_ouvrage()" />  
                <div class="custom_led" v-bind:class="{ custom: custom }" > </div>
                <feather-icon class="delete_ouvrage" icon="TrashIcon" size="17"  />  
            </div>
            <table class='table_pedra table'>
                <tr>
                    <td>{{ ouvrage.libelle }}</td>
                    <td>{{ ouvrage.time }}</td>
                    <td>{{ ouvrage.prix_u_ht }}</td>
                    <td>{{ ouvrage.prix_u_ht * qte }}</td>
                </tr>
                <tr>
                    <td colspan=3>TVA</td>
                    <td>
                        <b-form-select class="form-devis text-center form-select"
                            v-model="prix.tva_taux"
                            :options="optionsTVA"
                        />
                    </td>
                </tr>
                <tr> 
                    <td class="produit">+ fourniture</td>
                    <td></td>
                    <td>{{ouvrage.prix_fourniture}}</td>
                    <td class="">{{ qte * ouvrage.prix_fourniture }}</td>
                </tr>
                <tr> 
                    <td class="produit">+ marge</td>
                    <td></td>
                    <td></td>
                    <td class="input-row-total-marge" >{{ qte * prix.prix_marge1 }}</td>
                </tr>
                <tr>
                    <td class="produit text-right"> Total</td>
                    <td></td>
                    <td></td>
                    <td class="input-row-total-marge" >{{  ( prix.prix_ouvrage + prix.prix_marge1 ) }}</td>
                </tr>
            </table> 
            <div class="produits" v-show='!this.$store.state.display.view_client' > 
                <div class="libelle"> Produit </div> 
                <table class='table_pedra table table_produits' >
                    <tr v-for="produit in ouvrage.produits" :key = produit.id >
                        <Produit :id_produit=produit  :qte=qte :ouvrage=ouvrage  @updateProduit="updateProduit($event)" ></Produit>
                    </tr>
                    <tr>
                        <td class="">
                            <a class="add " @click="show_catalogue()">  Ajouter un produit</a>
                        </td>
                        <td>Total</td>
                        <td>{{ prix.prix_produits_u }}</td>
                        <td class="input-row-total-marge" >{{ prix.prix_produits }}</td>
                    </tr>
                </table>  
            </div> 
            <b-row class='calcul'>
                <b-col cols="2" >
                    Qté :  {{unite}} <br/>
                    <input class="form-control text-center " v-model.number=qte />
                </b-col>
                <b-col cols="2" >
                    Taux marge €<br/>
                    <input class="form-control" type="text"  v-model.number=prix.taux_marge1 placeholder="%">
                </b-col>
                <b-col cols="2" >
                    Marge temps<br/>
                    <input class="form-control input-total-row-horaire" v-model.number=heure.marge />
                </b-col>
                <b-col cols='2' class='text-right'>
                    Temps total : <br/>
                    {{ heure.total }} 
                </b-col>
                <b-col cols='3' class='text-right'>
                    Prix total : <br/>
                    {{ prix.prix_total }} 
                </b-col>
            </b-row>

        </div>
    </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BButton,  BCardCode,BFormSelect,BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,
} from 'bootstrap-vue'

    //import Produit from './ProduitComponent.vue'
    import  Vuex  from 'vuex';
    import axios from 'axios'


    export default {
        props:{
            row: { type : Object}
        },
        components : { 
            BRow, BCardCode, BFormSelect,BCol,BCard,BCardBody,BButton,BCardText,BForm,BFormGroup,BFormInput,BInputGroup,BInputGroupPrepend,BFormTextarea,BPopover,
            //Produit 
        },
        data(){
            return {
                id : this.row.id,
                order : this.order ,
                position   : 0 ,
                qte        : 1,
                zone : 0 , 
                type : this.row.type,
                text : this.row.text , 
                custom : 0 , 
                unite : "u", 
                idCatalogue : 0, 
                ouvrage : {
                    id : 0 , 
                    libelle    : "Libelle",
                    texte    : "du faux teste de ouf du faux teste de ouf du faux teste de ouf du faux teste de ouf du faux teste de ouf du faux teste de ouf du faux teste de ouf du faux teste de ouf du faux teste de ouf ",
                    time       : 0, 
                    prix_u_ht    : 0,
                    prix_fourniture    : 0,
                    famille : 0 , 
                    produits : {}
                },
                prix : {
                    prix_heure : 55,
                    prix_produits_u : 0,
                    prix_ouvrage : 0,
                    taux_marge1    : 0, 
                    prix_marge1    : 0, 
                    prix_marge2    : 0, 
                    prix_produits : 0,
                    prix_total : 0,
                    tva_taux : 10,
                    tva : 0,
                    prix_ttc : 0 , 
                },
                heure : {
                    ouvrage : 0,
                    produit    : 0, 
                    marge    : 0, 
                    total : 0,
                },
                optionsTVA: [
                    { value: 20, text: '20%' },
                    { value: 10, text: '10%' },
                    { value:  5, text: '5.5%' },
                    { value:  0, text: '0%' },
                ],
            }
        },
        watch: {
            'ouvrage.time' : function(val){
                console.log('coucou toi ')
                this.ouvrage.prix_u_ht = this.prix.prix_heure * val 
                this.calcul()
            },
            'prix.taux_marge1': function (val) {
                this.prix.prix_marge1  =  Math.ceil( this.prix.prix_ouvrage - (this.prix.prix_ouvrage * (1 - val / 100)) ) / 100 ;
                this.calcul()
            },
            'heure.marge': function (val) {
                this.prix.prix_marge2  =  Math.ceil(  val * this.prix.prix_heure * this.qte *100)/100
                this.heure.total = ( val + this.heure.ouvrage ) * this.qte
                this.calcul()
            },
            'qte': function (val) {
                console.log('watch' , val)
                this.prix.prix_marge2  =  Math.ceil( (  this.heure.marge * this.prix.prix_heure * val)  *100)/100   
                this.heure.total =  Math.ceil( this.heure.total * val *100)/100    
                this.calcul()
            },
            'prix.tva_taux': function (val) {
                console.log('watch tva' , val)
                this.prix.tva_taux  =  val*1
                this.calcul()
            },
        },
        computed : {
            
        },
        methods: {
            ...Vuex.mapActions([
                'majOuvrageState',
                'getTotal',
                'deleteOuvrage',
            ]),
            getOuvrage(id_ouvrage){
                axios.get("http://127.0.0.1:8000/api/ouvrages/"+id_ouvrage)
                    .then((response)=>{
                        console.log(response)
                        //init BDD
                        this.ouvrage.id           = response.data.ouvrage.id 
                        this.ouvrage.libelle      = response.data.ouvrage.libelle 
                        this.ouvrage.time         = response.data.ouvrage.heure 
                        this.ouvrage.famille      = response.data.ouvrage.familly_id 
                        this.ouvrage.texte        = response.data.ouvrage.description
                        

                        this.ouvrage.prix_u_ht    = response.data.ouvrage.prix_heure
                        this.ouvrage.prix_fourniture    = response.data.ouvrage.fourniture
                        this.ouvrage.produits     = response.data.list

                        //init calc
                        this.heure.ouvrage         = this.qte * response.data.ouvrage.heure
                        this.heure.total           = this.qte * response.data.ouvrage.heure 
                        this.prix.prix_ouvrage     = response.data.ouvrage.prix_heure + response.data.ouvrage.fourniture
                        this.prix.prix_marge1     = this.prix.prix_ouvrage - (this.prix.prix_ouvrage * (1 - this.prix.taux_marge1 / 100));
                        

                        this.calcul()
                    })
            },
            addProduit(id_produit){
                this.ouvrage.produits.push(id_produit)
            },
            updateProduit(data){
                console.log(data)
                this.prix.prix_produits_u += data.prix 
                this.heure.produit += data.marge_time 
                this.calculProduit()
                this.calcul()
            },
            calculOuvrage(){
                this.prix.prix_ouvrage =  ( this.ouvrage.prix_fourniture + this.ouvrage.prix_u_ht )  *  this.qte 
            },
            calculProduit(){
                this.prix.prix_produits = this.prix.prix_produits_u *  this.qte 
            },
            calcul(){
                this.calculOuvrage()
                this.calculProduit()
                this.prix.prix_total = Math.ceil( ( this.prix.prix_ouvrage +this.prix.prix_marge1 + this.prix.prix_produits + this.prix.prix_marge2 )*100 )/ 100
                this.prix.prix_ttc   = (this.prix.prix_total*(1+this.prix.tva_taux/100)).toFixed(2)*1;  

                //tva
                var new_tva =  this.prix.prix_ttc - this.prix.prix_total
                new_tva = Math.ceil(new_tva*100)/100
                this.prix.tva        = new_tva
            
                this.majOuvrageState(this)
            },
            show_catalogue(){
                this.$store.state.display.catalogue_produit =  1
                this.$store.state.famille_actif =  this.ouvrage.famille ; 
                this.$store.state.ouvrage_actif =  this ; 
            },
            delete_ouvrage(){
                this.deleteOuvrage(this)
            },
            update_ouvrage(){
                this.$store.state.display.custom_ouvrage = 1
                this.$store.state.custom_ouvrage_actif.action = "update"
                this.$store.state.component.ouvrage = this
                this.$store.commit("OPEN_POP_CUSTOM_OUVRAGE")
            },
            getLibelle(){  return this.ouvrage.libelle },
            getTexte(){  return this.ouvrage.texte },
            getUnite(){  return this.unite },
            getTime(){  return this.ouvrage.time },
            getCustom(){  return this.custom },
            getIdBDD(){  return this.ouvrage.id },
            getIdCatalogue(){  return this.idCatalogue },
            setLibelle(libelle){ this.ouvrage.libelle = libelle },
            setLibelle(libelle){ this.ouvrage.libelle = libelle },
            setTexte(texte){ this.ouvrage.texte = texte },
            setUnite(v){ this.unite = v },
            setTime(v){ console.log(v) ; this.ouvrage.time = v },
            setCustom(v){ console.log(v) ; this.custom = v },
           
        },
        created() {
            this.order = this.row.order
            this.zone      = this.$store.getters.getZoneActif
            if(this.row.type == 'ouvrage'){
                this.qte = this.row.qte
                this.prix.taux_marge1 = this.row.marge
                this.heure.marge = this.row.heure
                this.getOuvrage(this.row.bdd) 
            }

            
        },
        
    };
</script>

<style lang="scss" scoped>
    @import '../devis.scss';
</style>

<style lang="scss">
.table td {
    padding: 5px 0;
}
.new_block_ouvrage{
    padding: 5px;
}
.ouvrage_action{
    position: absolute;
    right: -20px;
    top: 0px;
    bottom: 0px;
    width: 20px;
    text-align: center;
}
.edit_ouvrage{
    margin-top: 20px;
}
.custom_led {
    background-color: #FFF;
    border: 1px solid silver;
    margin-top: 20px;
    border-radius: 30px;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    top: 50px; 
    left:4px;
}
.custom{
    background: #c8ae85;
    background-color: #c8ae85;
}
.delete_ouvrage{
    position: absolute;
    bottom: 5px;
    left: 4px;
}
</style>
import Vuex from 'vuex'

const getters = {
	getZones : (state) => {
		return state.zones 
	},
	getZoneActif : (state) => {
		return state.zone_actif 
	},
	getZoneById: (state , id ) =>  {
		return state.zones.find( zone => zone.id === id)
	},
	getCountZone: (state) => {
		if(state.zones === undefined){
			return 0
		}
		return state.zones.length
	},
	getFamilleById: (state , id , id_zone ) => {
		let zone = getters.getZoneById(state , id_zone)
		if(zone === undefined){
			//alert('un problème, il semble que la famille nest pas dans la zone ')
			return false ; 
		}
		var isFamille = zone.familles.find( famille => famille.id === id )
		if(isFamille === undefined){
			return false ; 
		}else{
			return isFamille
		}
	},
	change_zone_actif : ( state , id) => {

	},
	getOuvrageByFamilleById :  ( state , data ) => {
		let zone = getters.getFamilleById(state , data.famille ,data.zone)

		var isOuvrage = zone.rows.find( ouvrage => ouvrage.bdd === data.ouvrage )
		if(isOuvrage === undefined){
			return false ; 
		}else{
			return isOuvrage
		}
	}
}

const mutations = {
	INIT_DEVIS : (state  , data) => {
		if(data){
			state.devis_id = data.id
			state.zones = (data.json.zones)
			state.zone_actif = 1
		}

		//state.liste_famille = axios.get("/admin/devis/get_familles").then( result => { return (result.data)   })  
	},
	ADD_ZONE : (state ) => {
		var count = getters.getCountZone(state)+1 ;
		let zone  = { 
			id: count , 
			name : "zone "+count , 
			position : 0 , 
			familles : []
		}
		if(state.zones == undefined){
			state.zones = []
		}
		state.zones.push(zone)
		state.zone_actif = count
		return state.zone_actif
	},
	ADD_FAMILLE: ( state , data    ) => {
		if(data.zone != undefined){
			var zone_actif = data.zone
			var famille = data.famille
		}else{
			var zone_actif = getters.getZoneActif(state);
			var famille = data
		}
	    let title = "Libelle famille";

		var famille = {
			id : famille , 
			order : 1 , 
			total : 0 ,
			name : title , 
			rows : [] , 
			ouvrages : []
		}
		getters.getZoneById( state , zone_actif ).familles.push(famille);
		return famille
	},
	ADD_OUVRAGE: (state , data  ) => {

		var zone_actif = getters.getZoneActif(state);
		var ouvrage = []
		ouvrage.id = data.id
		ouvrage.familly_id = data.famille

		var nbZone = getters.getCountZone(state) ; 

		if(nbZone == 0){
			zone_actif = mutations.ADD_ZONE(state) 
		}

		if(!zone_actif > 0){
			zone_actif = mutations.ADD_ZONE(state) 
		}



		var isFamille = getters.getFamilleById( state , ouvrage.familly_id , zone_actif)
		


		if(isFamille == false){
			var famille  = mutations.ADD_FAMILLE(state , ouvrage.familly_id , zone_actif ) 
		}else{
			var famille = isFamille
		}



		let ouvrage_row = {
			type : 'ouvrage' ,
			id : (new Date()).getTime() ,
			bdd : ouvrage.id ,
			order : 1, 
			qte : 1 , 
			marge :0 , 
			heure : 0,
			prix : 0 ,
			tva_taux : 0 ,
			tva : 0 ,
			ttc : 0 ,
			produits : [] , 
		}
		famille.rows.push(ouvrage_row)
	},
	OPEN_POP_CUSTOM_OUVRAGE : (state) => {
		console.log("-----DEBUG-------")
		
		if(state.custom_ouvrage_actif.action == "update"){
			let component_ouvrage = state.component.ouvrage

			state.custom_ouvrage_actif.title  = "Modifier l'ouvrage";
			state.component.pop_custom_ouvrage.setLibelle(component_ouvrage.getLibelle()) ;
			state.component.pop_custom_ouvrage.setTexte(component_ouvrage.getTexte()) ;
			state.component.pop_custom_ouvrage.setUnite(component_ouvrage.getUnite()) ;
			state.component.pop_custom_ouvrage.setTime(component_ouvrage.getTime()) ;


			console.log(component_ouvrage.getLibelle())
			console.log(component_ouvrage.getTexte())
			console.log(component_ouvrage.getUnite())
			console.log(component_ouvrage.getTime())

		
			
		}


		


	
	},
	UPDATE_CUSTOM_OUVRAGE :  (state ) => {
		var action = state.custom_ouvrage_actif.action 
		console.log(action)
		console.log(state.component.pop_custom_ouvrage.getLibelle())
		console.log(state.component.pop_custom_ouvrage.getTexte())
		console.log(state.component.pop_custom_ouvrage.getUnite())
		console.log(state.component.pop_custom_ouvrage.getTime())
		
		if(action == "update"){
			let component_ouvrage = state.component.ouvrage
			component_ouvrage.setLibelle(state.component.pop_custom_ouvrage.getLibelle())
			component_ouvrage.setTexte(state.component.pop_custom_ouvrage.getTexte())
			component_ouvrage.setUnite(state.component.pop_custom_ouvrage.getUnite())
			component_ouvrage.setTime(state.component.pop_custom_ouvrage.getTime())
			component_ouvrage.setCustom(1)
		}


		state.display.custom_ouvrage = 0
	},
	ADD_PRODUIT: (state , id_produit ) => {
		console.log('on ajoute un produit')
		
		state.ouvrage_actif.addProduit(id_produit)
		state.display.catalogue_produit = 0 ; 
		
	},
	SHOW_CATALOGUE : (state , open , ouvrage ) => {
		state.toggleCatalogue = open 
		console.log(ouvrage)
	},
	MAJ_OUVRAGE : (state , data  ) => {
		// c'est ici que l'on mets à jour les ouvrage 



		data.row.qte      = data.composant.qte
		data.row.marge    = data.composant.prix.taux_marge1 
		data.row.heure    = data.composant.heure.total 
		data.row.prix     =  data.composant.prix.prix_total 
		data.row.tva_taux = data.composant.prix.tva_taux 
		data.row.tva      = data.composant.prix.tva 
		data.row.ttc      = data.composant.prix.prix_ttc 
		
		let r = {
			"famille" : data.composant.ouvrage.famille , 
			"zone" : data.composant.zone
		} 
	},
	MAJ_FAMILLE : (state , data ) => {
		let famille = getters.getFamilleById( state ,  data.famille , data.zone)
		var oldTotal = famille.total
		var NewTotal = 0 
		famille.rows.forEach(row => {
			NewTotal  += 1 * row.prix 
		});
		famille.total = NewTotal
	},
	CHANGE_ZONE : (state , zoneId) => {
		
		state.zone_actif = zoneId ; 
		//getters.change_zone_actif( state ,  zoneId )
	},
	CALC_FAMILLE : (state , famille_id) => {
		let famille = getters.getFamilleById( state ,  famille_id , getters.getZoneActif(state))

		let prix   = 0 ; 
		let time = 0 ; 

		//parcours des zones
		famille.rows.forEach(row => {
			console.log(row)
			prix += row.prix
			time += row.heure
		});	
		famille.total = prix ;

		mutations.CALC_DEVIS(state);


	},
	CALC_DEVIS : (state) => {
		console.log('ici on calcule le devis')
	
		let total   = 0 ; 
		let total_TTC   = 0 ; 
		let time = 0 ; 
		let tva_0  = 0 ; 
		let tva_5  = 0 ; 
		let tva_10 = 0 ; 
		let tva_20 = 0 ; 
		let tva_general = 0 ; 
		let pf = 0 ; 

		//parcours des zones
		store.state.zones.forEach(zone => {
			zone.familles.forEach(famille => {
				total += famille.total
				
				famille.rows.forEach(row => {
					
					switch (row.tva_taux) {
					  case 0:  tva_0 += row.tva ; break;
					  case 5.5:  tva_5 += row.tva ; break;
					  case 10: tva_10 += row.tva ; break;
					  case 20: tva_20 += row.tva ; break;
					}
					tva_general += row.tva ; 
					pf += row.prix
					time += row.heure
				});

			});
		});


		
		// MISE A JOUR TVA 
		store.state.tva.tva0  = tva_0.toFixed(2) ;
		store.state.tva.tva5  = tva_5.toFixed(2) ;
		store.state.tva.tva10 = tva_10.toFixed(2) ;
		store.state.tva.tva20 = tva_20.toFixed(2) ;
		
		// MISE A JOUR PRIX 
		store.state.prix.ht   = total.toFixed(2) ;
		store.state.prix.tva = tva_general.toFixed(2) ;
		store.state.prix.ttc = total +  tva_general ;

		//TIME
		store.state.time.horaire = time.toFixed(2) ; 

		

	},
	MAJ_TITLE_ZONE : (state , data) => {
		getters.getZoneById(state , data.zone).name = data.title
	},
	MAJ_TITLE_SUBZONE : (state , data) => {
		let famille = getters.getFamilleById( state ,  data.famille , getters.getZoneActif(state))
		famille.name = data.libelle ; 
	}
	
}


const actions = {
	getDevis : (store , id ) => {
		store.commit('INIT_DEVIS' , id )
	},
	addZone  : (store) => {
		store.commit('ADD_ZONE')
	},
	deleteZone  : (store , zone) => {
		//store.commit('mutation')
	},
	addFamille  : (store , data  ) => {
		store.commit('ADD_FAMILLE' , data )
	},
	getFamille  : (store , id_famille  ) => {
		axios.get("/admin/devis/get_famille/"+id_famille)
			.then(result => {
				return result.data
		})	
	},
	deleteFamille  : (store , data ) => {
		store.state.zones[data.indexZone].familles.splice(data.indexFamille, 1);
	},
	addOuvrage  : (store , ouvrage  ) => {
		//actions.addZone(store)
		//actions.addFamille( store , { famille : 13, zone : 1} )
		axios.get("/admin/devis/get_ouvrage/"+ouvrage)
			.then(result => {
				store.commit("ADD_OUVRAGE", result.data);
		})	  
		return false 
	},
	getFamille  : (store , id_famille  ) => {
		axios.get("/admin/devis/get_famille/"+id_famille)
			.then(result => {
				return result.data;
		})	  
		return false 
	},
	deleteOuvrage  : (store , data  ) => {
		// on mets à jour le json 
		var indexZone = data.zone - 1 ; 

		var famille = store.state.zones[indexZone].familles.find( famille => famille.id === data.ouvrage.famille )
		let indexFamille = store.state.zones[indexZone].familles.indexOf(famille)



		var row = store.state.zones[indexZone].familles[indexFamille].rows.find( row => row.id === data.id )
		let indexRow = store.state.zones[indexZone].familles[indexFamille].rows.indexOf(row)
		
		store.state.zones[indexZone].familles[indexFamille].rows.splice(indexRow, 1);

		// on test si la famille n'est pas vide 
		var nbOuvrageByFamille = store.state.zones[indexZone].familles[indexFamille].rows.length

		var r = {
			"zone" : data.zone,
			"indexZone" : indexZone,
			"famille"  : data.ouvrage.famille,
			"indexFamille"  : indexFamille
		}

		actions.calcul_famille(store , r)

		if(nbOuvrageByFamille == 0 ){
			actions.deleteFamille(store, r)
		}

		mutations.CALC_DEVIS(store.state);
		//store.commit('DELETE_OUVRAGE' , data)
	},
	addProduit  : (store , produit , ouvrage , famille , zone ) => {
		//store.commit('mutation')
	},
	deleteProduit  : (store , produit , ouvrage , famille , zone ) => {
		//store.commit('mutation')
	},
	majOuvrageState  : (store  , data  ) => {
		let famille = getters.getFamilleById( store.state ,  data.ouvrage.famille , data.zone).rows

		if(famille){
			let row = famille.find( ouvrage => ouvrage.id === data.id )
			if(row){
				let ouvrage_row = {
					composant : data ,
					row : row ,
				}
				store.commit('MAJ_OUVRAGE' , ouvrage_row   )
			}
			store.commit('CALC_FAMILLE' , data.ouvrage.famille    )
		}	
	},
	updateMapping : (store) => {
		
	},
	calcul_famille : (store) => {
		
	},
	
}

let store = new Vuex.Store({
    state: {
    	liste_famille : [],
        display : {
			search_ouvrage : true ,
			custom_ouvrage : false ,
			zone_note : false ,
			row_ouvrage :  true ,  
			catalogue_produit : false ,
			catalogue_ouvrage : true , 
			light : false , 
			skiny : false , 
			search : false ,
			view_client : false ,  
			view_pedra : false ,  
			view_all : true , 
			view_mini : false , 
		},
		component : {
			ouvrage : { } , 
			pop_custom_ouvrage     : { } , 
		},
		zone_actif : 0 ,
		famille_actif : {},
		ouvrage_actif : {},
		custom_ouvrage_actif : {
			libelle : "",
			catalogue   : {} , 
			component   : {} , 
            title       : "Ajouter un ouvrage",
            libelle     : "un libelle ",
            texte       : "",
            unite       : "forfait",
            idCatalogue : 0, 
            famille     : 0,
            categorie   : 0,
            time        : 20,
            id_ouvrage  : 0,
            custom      : 1,
            action      : 'add',
		} , 
		prix : {
			ht :  0 ,
			tva : 0 , 
			ttc : 0 ,
		},
		tva : {
			tva0   : 0 ,
			tva5   : 10 , 
			tva10  : 0 ,
			tva20  : 0 ,
		},
		stats : {
			ouvrage : 0 ,
			famille : 0 , 
		},
		time : {
			horaire : 0 ,
		},
		devis_id : 0 , 
		zones : [],
		search : {
			ouvrage : {},
			produit : {},

		}
    },
    mutations: mutations,
    getters : getters,
    actions : actions,
})


global.store = store

export default store
import { render, staticRenderFns } from "./devisAdd.vue?vue&type=template&id=842ecb06&scoped=true&"
import script from "./devisAdd.vue?vue&type=script&lang=js&"
export * from "./devisAdd.vue?vue&type=script&lang=js&"
import style0 from "./devisAdd.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./devisAdd.vue?vue&type=style&index=1&id=842ecb06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "842ecb06",
  null
  
)

export default component.exports
<template>
    <b-card>
        <div id="tva" class='tva'>
            <div class="card-header">
                <h4 class="card-title">TVA</h4>
            </div>
                <table class="table">
                 
                    <tr>
                        <td>0 %</td>
                        <td class="text-right"> {{ $store.state.tva.tva0 }} €</td>
                    </tr>
                    <tr>
                        <td>5.5 %</td>
                        <td class="text-right"> {{ $store.state.tva.tva5 }} €</td>
                    </tr>
                    <tr>
                        <td>10 %</td>
                        <td class="text-right"> {{ $store.state.tva.tva10 }} €</td>
                    </tr>
                    <tr>
                        <td>20 %</td>
                        <td class="text-right"> {{ $store.state.tva.tva20 }} €</td>
                    </tr>
            </table>
        </div>
    </b-card>
</template>

<script>
    import  Vuex  from 'vuex';
    import {
  		BRow, BCol, BCard, VBToggle,
	} from 'bootstrap-vue'
    export default {
        components : { 
            BCard,
		},	
        mounted() {
        },
        data(){
            return {
                //visible : this.$store.state.toggleCatalogue,
            }
        },
        methods: {
           
           
        }
    };
</script>
<template>
    <div id="module_price" class='module_price'>
		<b-card>
			<div class="card-header">
                <h4 class="card-title">Prix</h4>
            </div>
			<div class="block ">
				<div class='row'>
					<div class="col-6">
						HT
					</div>
					<div class="col-6 text-center">
						{{ $store.state.prix.ht }} €
					</div>
				</div>
			</div>
			<div class="block ">
				<div class='row'>
					<div class="col-6">
						TVA
					</div>
					<div class="col-6 text-center">
						{{ $store.state.prix.tva }} €
					</div>
				</div>
			</div>
			<div class="block ">
				<div class='row'>
					<div class="col-6">
						TTC
					</div>
					<div class="col-6 bColor">
						{{ $store.state.prix.ttc }} €
					</div>
				</div>
			</div>
		</b-card>
		

	 	<b-card>
			<div class="card-header">
                <h4 class="card-title">Temps</h4>
            </div>
			<div class="block ">
				<div class='row'>
					<div class="col-6">
						Temps 
					</div>
					<div class="col-6 text-center">
						{{ $store.state.time.horaire }} 
					</div>
				</div>
			</div>
	     	<br/>
			<div class="block ">
				<div class='row'>
					<div class="col-6">
						Nb ouvrage 
					</div>
					<div class="col-6 text-center">
						{{ $store.state.stats.ouvrage }} 
					</div>
				</div>
				<div class='row'>
					<div class="col-6">
						Nb corps de métier 
					</div>
					<div class="col-6 text-center">
						{{ $store.state.stats.famille }} 
					</div>
				</div>
			</div>
		</b-card>

    </div>
</template>

<script>
    import  Vuex  from 'vuex';
	import {
  		BRow, BCol, BCard, VBToggle,
	} from 'bootstrap-vue'
    export default {
		components : { 
            BCard,
		},	
        mounted() {
           
        },
        data(){
            return {
                //visible : this.$store.state.toggleCatalogue,
            }
        },
        methods: {
           
           
        }
    };
</script>


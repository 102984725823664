<template>
    <div>
        <div class="customOuvrage popUp" v-if='this.$store.state.display.custom_ouvrage'> 
            <div class='pop'>
                <div class="title">
                    {{this.$store.state.custom_ouvrage_actif.title}}

                    <div class="close_pop" @click="close()" ></div>

                </div>
                <div class="cardBody">
                    <div class='row'>
                        <div class="col-md-12 mb-3">
                            <label for="titre_form">Libellé</label>
                            <input name="libelle" type="text" class="form-control" v-model=libelle >     
                        </div>
                    </div>

                    <div class='row'>
                        <div class="col-md-3 mb-3">
                            <label for="titre_form">Unité</label>
                            <select name="unite" type="text" class="form-control select2-single" required="" v-model=unite>
                                <option value="forfait">forfait</option>
                                <option value="m2">m2</option>
                                <option value="m3">m3</option>
                                <option value="lineaire">linéaire</option>
                                <option value="kg">kg</option>
                                <option value="u">u</option>
                            </select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="titre_form">Temps</label>
                            <input name="libelle" type="text" class="form-control" required="" v-model=time>
                        </div>

                        <div class="col-md-5 mb-3 " v-show='this.$store.state.custom_ouvrage_actif.action == "add"' >
                            <label for="titre_form">Associé à </label>
                            <select name="category_id" type="text" class="form-control select2-single" required="">
                                <optgroup v-for="(famille, id_f) in catalogue" :label="famille.name">
                                <option v-for="(categorie, id_c )  in famille.categories" :value="id_c">
                                    {{ categorie.name }}
                                </option>
                                </optgroup>	
                            </select>
                        </div>
                    </div>


            
                    <div class='row'>
                        <div class="col-md-12 mb-3">
                            <label for="titre_form">Description ( vue devis client ) </label>
                            <quill-editor
                                v-model="texte"
                            />
                        </div>
                    </div>

                </div>

                <div class='row'>
                    <b-button
                            id="back_button"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="dark"
                            @click="update_custom_ouvrage()"
                        >
                        Valider
                        </b-button>
                    
                </div>
                

            </div>

            
        </div>

        <div class="ZoneNote popUp" v-if='this.$store.state.display.zone_note' >
            <div class='pop'>
                <div class="title">
                    {{this.$store.state.zone_actif.title}}

                    <div class="close_pop" @click="close()" ></div>

                </div>
                <div class="cardBody">
                    <div class='row'>
                        <div class="col-md-12 mb-3">
                            <label for="titre_form">Note ( vue devis client ) </label>
                            <quill-editor
                                v-model="note"
                            />
                        </div>
                    </div>

                </div>

                <div class='row'>
                    <b-button
                            id="back_button"
                            variant="dark"
                            @click="update_custom_ouvrage()"
                        >
                        Valider
                        </b-button>
                    
                </div>
                

            </div>

        </div> 
    </div>
</template>

<script>
    import axios from 'axios'
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import { quillEditor } from 'vue-quill-editor'
    import {
        BButton,
    } from 'bootstrap-vue'

    export default {
        components : { 
            quillEditor,BButton
        },
        mounted() {
            //this.getFamille()
            this.$store.state.component.pop_custom_ouvrage = this
        },
        data(){
            return {
                position   : "1",
                catalogue   : {} , 
                title  : "Ajouter un ouvrage",
                libelle   : "sssss",
                texte     : "",
                unite     : "forfait",
                famille   : 0,
                categorie   : 0,
                time        : 0,
                id_ouvrage  : 0,
                note : '',

            }
        },
        computed : {
            
        },
        watch: {
        	
        },
        methods: {
            getFamille(){
                axios.get("/admin/devis/get_catalogue")
                     .then((response)=>{
                         //init BDD
                         console.log('response')
                         console.log(response)
                         this.catalogue = response.data
                    })
            },
            addCustomeOuvrage(){

            },
            update_custom_ouvrage(){
            	this.$store.commit("UPDATE_CUSTOM_OUVRAGE")
            },
            close(){
            	this.$store.state.display.zone_note = 0
            	this.$store.state.display.custom_ouvrage = 0
            },
            getLibelle(){ return this.libelle },
            getTexte(){   return this.texte },
            getUnite(){   return this.unite },
            getTime(){    return this.time },
            setLibelle(v){ this.libelle = v  },
            setTexte(v){ this.texte = v  },
            setUnite(v){ this.unite = v  },
            setTime(v){ this.time = v  },
            
        },
        created(){
          
          
        }
    };
</script>


<style lang="scss" scoped>
.popUp {
    background: #05374bab;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999999;

    .pop{
        position: fixed;
        left: 50%;
        margin-left:-400px ;
        top: 100px;
        width: 800px;
        background: #FFF;
        z-index: 999;
    }

    .title {
        height: 60px;
        line-height: 60px;
        width: 100%;
        background: #05374b;
        color: #FFF;
        font-size: 40px;
        font-weight: bold;
        padding: 0 20px;
    }

    .cardBody {
        padding: 20px;
    }

    .close_pop {
        position: absolute;
        top: 0px;
        right: 0px;
        height: 60px;
        width: 60px;
        background: red;
    }




}
</style>
<template>
    <div class="famille">
        <div class="position"> {{ order }} </div>
        <div class="table_total">
            {{ name }}  
            <div class="total" >  {{ this.famille.total }} € </div>
        </div>
        <div class="" v-show='this.$store.state.display.row_ouvrage' >  
            <div class="ouvrage"   v-for="( row  , index  ) in rows"  v-bind:key="index+row.id" >
                <ouvrage  :row=row ></ouvrage>
            </div>
        </div>
    </div>
</template>

<script>
import Ouvrage from './Ouvrage.vue'
import axios from 'axios'
export default {
    mounted() {
        this.id = this._uid
    },
    components : { 
        Ouvrage 
    },
    props:{
        zone : { type : Number },
        famille :{ type : Object },
    },
    computed : {
        
    },
    watch: {
        'famille': function (val) {
            console.log('watch famille' , val)
        }
    },
    data() {
        return {
            //ouvrages : [] , 
            addNewProduct : null  ,
            name : null ,
            rows : null , 
            order : this.order , 
            group : "groupe-"+this.zone+"-"+this.famille.id , 
            total : 0 ,
            
        }
    },
    methods : {
        getFamille(famille){
            axios.get("/admin/devis/get_famille/"+famille)
                .then((response)=>{
                    this.name = response.data.libelle ; 
                    let data = {
                        famille : response.data.id , 
                        libelle : response.data.libelle , 
                    }
                    this.$store.commit('MAJ_TITLE_SUBZONE' , data)
            })
        },
        getTotal(){
            console.log('ici on mets à jour la famille')
        }
    },
    created() {
        this.order  = this.famille.order
        this.rows   = this.famille.rows
        this.name   = this.famille.name
        //this.getFamille(this.famille.id)
        this.total = 0 
    },
    updated() {
        //this.getFamille(this.famille.id)
        
    },
    destroyed() {
        
    },
};
</script>

<style>
  


</style>

<style lang="scss" scoped>
@import '../devis.scss';
</style>
<template>
  <section class="devis invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="2"
      >
        <div class="sidebar-left">
          <b-button
            id="back_button"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="dark"
             :to="{ name: 'devis'}"
          >
            Retour aux devis
          </b-button>
         


          <div class='devis-mapping'>
            Mapping
            <ul class="wrap-zonning" >
                <li class="zoning" 
                    v-for="( zone  ) in $store.state.zones " 
                    v-bind:key="zone.id"  
                    v-bind:class="{ active: zone.id  == $store.state.zone_actif }"
                    @click="setActiveZone( zone.id )" 
                    >
                    <div>{{ zone.name }}</div>
                    <ul>
                        <li v-for="( famille  ) in zone.familles " >{{ famille.name }}  </li>
                    </ul>
                </li>
            </ul>
            <b-button
                id="back_button"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="dark"
                @click="addZone"
              >
              Ajouter une zone
            </b-button>
          </div>
          
        </div>
      </b-col>
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="10"
        xl="7"
        md="6"
        class="wrap-travail"
      >
        <b-form @submit.prevent>
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">

              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                    <h3 class="text-primary invoice-logo">
                      Vuexy
                    </h3>
                  </div>
                  <b-card-text class="mb-25">
                    Office 149, 450 South Brand Brooklyn
                  </b-card-text>
                  <b-card-text class="mb-25">
                    San Diego County, CA 91905, USA
                  </b-card-text>
                  <b-card-text class="mb-0">
                    +1 (123) 456 7891, +44 (876) 543 2198
                  </b-card-text>
                </div>

                <!-- Header: Right Content -->
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div class="d-flex align-items-center justify-content-md-end mb-1">
                    <h4 class="invoice-title">
                      Invoice
                    </h4>
                    <b-input-group class="input-group-merge invoice-edit-input-group disabled">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="HashIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="invoice-data-id"
                        v-model="invoiceData.id"
                        disabled
                      />
                    </b-input-group>
                  </div>
                  <div class="d-flex align-items-center mb-1">
                    <span class="title">
                      Date:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.issuedDate"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                  <div class="d-flex align-items-center">
                    <span class="title">
                      Due Date:
                    </span>
                    <flat-pickr
                      v-model="invoiceData.dueDate"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">
            <!-- Items Section -->
            <b-card-body id="devis" class="invoice-padding form-item-section">
                <div v-for="zone in zonesComputed " v-bind:key="zone.id" v-bind:id="'zone'+zone.id" >
                  <zone :zone = zone :id="'zone'+zone.id"   ></zone>
                </div>
            </b-card-body>
          </b-card>
        </b-form>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Send Invoice -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            disabled
          >
            Planifier
          </b-button>

          <!-- Button: DOwnload -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
          >
            Prévisualiser
          </b-button>

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click=save()
          >
            Sauvegarder
          </b-button>

        </b-card>
         
        <recap></recap>
      
      </b-col>
    </b-row>

    <b-row class="nav_custom_bottom">

      
            <b-col
              cols="2"
              class="flex-center"
            >
              <div id="number_ouvrage" class="number_ouvrage">
               
                  Ouvrage trouvé(s)
                  <br/>
                  <div>{{nbResult}}</div>
               
              </div>
            </b-col>
            <b-col
              cols="10"
              xl="7"
              md="6"
              class="wrap-travail"
            >
              <input id="engine" ref="engine" type="text" class="form-control engine" placeholder="Taper votre ouvrage" @keyup="search" v-model=pattern  >
          
              <div id="result" ref="result" class="result_ouvrage" v-bind:style="{  height: newHeight  }" v-show="nbResult>0">
                  <table class="table table-pedra table-striped">    
                      <tbody>
                          <tr v-for="( row  ) in result " v-bind:key="row.id" class=""    >
                              <td style="font-size:12px">{{ row.libelle }}</td>
                              <td>{{ row.total}}</td>
                              <td>
                                  <a href="#" 
                                      v-on:keyup.esc = focusSearch
                                      @click="addOuvrage( row.id , row.familly_id )" 
                                  >Ajouter</a>
                              </td> 
                          </tr>
                      </tbody>
                  </table>
              </div>
            </b-col>
      
    
    </b-row>

    <customOuvrage></customOuvrage>

  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {
  BRow, BCol, BCard, BCardBody, BButton, BCardText, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BFormTextarea, BFormCheckbox, BPopover, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
 import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import invoiceStoreModule from '../devisStoreModule'

import  Vuex  from 'vuex';
import Map from '../components/Map.vue'
import Zone from '../components/Zone.vue'
import Recap from '../components/Recap.vue'
import CustomOuvrage from '../components/CustomOuvrage.vue'
import Store from '../storeDevis.js'



import axios from 'axios'

export default {
  store : Store, 
  components: {
    BRow,BCol,BCard,BCardBody,BButton,BCardText,BForm,BFormGroup,BFormInput,BInputGroup,BInputGroupPrepend,BFormTextarea,BPopover,
    flatPickr,
    vSelect,
    Logo,
    ToastificationContent,
    Map,Zone,Recap,CustomOuvrage
  },
  props:{
      id : { type : Number }, 
      state : { type : Object }  ,
      liste_famille : { type : Object }  
  },
  directives: {
    Ripple,'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  mounted() {
    
  },
  created() {

    /*
    axios.get("http://127.0.0.1:8000/api/devis/21")
        .then((response)=>{
            let data = {
              id : response.data.devis.id,
              json : response.data.devis.json,
              //liste_famille : this.liste_famille
            }
            console.log(data)
            this.getDevis(data)
    })
    */
    
      
  },
  destroyed() {
   
  },
  computed : {
      zonesComputed(){
          return this.$store.getters.getZones
      }
  },
  methods: {
    ...Vuex.mapActions([
        'getDevis',
    ]),
    search(event){
        axios.post("http://127.0.0.1:8000/api/ouvrages/search", { query : this.pattern } )
        .then((response)=>{
            this.result = response.data.result
            this.nbResult = Object.keys(response.data.result).length  ; 
        })
    },
    addOuvrage(id , famille ){
      this.$store.commit("ADD_OUVRAGE" ,  { id , famille } )
    },
    addZone(){
      this.$store.commit("ADD_ZONE")
    },
    setActiveZone(id){
      this.$store.commit('CHANGE_ZONE' , id)
    },
    save(){
      console.log('save')
      let item = (this.$store.state)
      console.log(item)


      store.dispatch('app-invoice/addDevis2' , item )
          .then(response => { 
              console.log(response)
              
              this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      text: '👋 le devis a été sauvegardé.',
                      variant : 'success'
                  },
              })
          })
          .catch(error => {
              console.log(error)
              this.$toast({
              component: ToastificationContent,
              props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: "error",
                  variant : 'danger'
              },
              })
          })         
      }
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    const clients = ref([])
   
    const itemFormBlankItem = {
      item: null,
      cost: 0,
      qty: 0,
      description: '',
    }

    const invoiceData = ref({
      id: 5037,
      client: null,
      // ? Set single Item in form for adding data
      items: [JSON.parse(JSON.stringify(itemFormBlankItem))],
      salesPerson: '',
      note: 'It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!',
      paymentMethod: null,
    })

    const itemsOptions = [
      {
        itemTitle: 'App Design',
        cost: 24,
        qty: 1,
        description: 'Designed UI kit & app pages.',
      },
      {
        itemTitle: 'App Customization',
        cost: 26,
        qty: 1,
        description: 'Customization & Bug Fixes.',
      },
      {
        itemTitle: 'ABC Template',
        cost: 28,
        qty: 1,
        description: 'Bootstrap 4 admin template.',
      },
      {
        itemTitle: 'App Development',
        cost: 32,
        qty: 1,
        description: 'Native App Development.',
      },
    ]

    const updateItemForm = (index, val) => {
      const { cost, qty, description } = val
      invoiceData.value.items[index].cost = cost
      invoiceData.value.items[index].qty = qty
      invoiceData.value.items[index].description = description
    }

    const paymentMethods = [
      'Bank Account',
      'PayPal',
      'UPI Transfer',
    ]

    return {
      invoiceData,
      clients,
      itemsOptions,
      updateItemForm,
      itemFormBlankItem,
      paymentMethods,
    }
  },
  data() {
    return {
        pattern : null , 
        newHeight : 300 , 
        zones : [] , 
        result : {} ,
        nbResult : 0 ,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

@import '../devis.scss';
.nav_custom_bottom{
  position: fixed;
  z-index: 999;
  background: #FFF;
  bottom: 0px;
  left:0px;
  right: 0px;
  padding:10px 30px;
  height: 300px;
  border-top: 4px solid #7367f0;

 
    
    .engine{
      margin-bottom: 10px;
    }
    .result_ouvrage{
      height: 200px;
      z-index: 9;
      width: 100%;
     
      overflow: scroll;
    }
    .flex-center{
       display: flex;
      justify-content: center;
      align-items: center;
    }
    .number_ouvrage{
      text-align: center;
     

      div{
         margin-top:10px ;
         font-size: 40px;
         font-weight: bold;
         color: var(--color1);
      }
     
    }


  
}
.wrap-travail{
  margin-top: 20px;
}

.invoice-add{
  padding-bottom:300px ;
}



.sidebar-left{
  width:260px;
}

#back_button{
  margin: 1.3rem 2rem 0;
}
.invoice-actions{
  position: fixed;
  top:20px;
  right: 15px;
  width: 320px;
}

</style>

<template>
    <div class="zone">
        <div class="barAction">
             <b-button-group>
                <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                >
                    <feather-icon icon="EditIcon" size="15"  @click="addNote()" />  
                </b-button>
                <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                >
                    <feather-icon icon="TrashIcon" size="15"  />  
                </b-button>
            </b-button-group>
        </div>
        <div class="libelle_zone">
            <input class="form-control" type="text" v-model=name />
        </div>
        <div class="zone-body">
            <div v-for="( famille  ) in familles" v-bind:key="famille.id" class="" >
                <famille :famille = famille :zone = zone.position ></famille>
            </div>
        </div>
    </div>
</template>

<script>
import {
  BButtonToolbar,BButtonGroup,BButton, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

    import Famille from './Famille.vue'

    export default {
        mounted() {
            const el = document.getElementById('zone'+this.$store.state.zone_actif );
		    //VueScrollTo.scrollTo(el , 1000 , optionsScroll )
        },
        components : { 
            Famille,
            BButtonToolbar,
            BButtonGroup,
            BButton,
           
        },
        directives: {
            Ripple,
            'b-tooltip': VBTooltip,
        },
        props:{
            zone : { type : Object },
        },
        computed : {
            orderedFamille: function () {
                return _.orderBy(this.familles, 'order')
            },
            dragOptions() {
                return {
                    animation: 200,
                    group: "familles",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
            mehdi : function(){
                return this.$store.state.mehdi 
            }
        },
        watch : {
            'name': function (val) {
                let r = {
                    "zone" : this.zone.id , 
                    "title" : val
                }
                this.$store.commit('MAJ_TITLE_ZONE' , r)
            },
        },
        data() {
            return {
                name : this.$store.state.mehdi,
                total : 0 ,
                familles : null , 
                group : '' ,
            }
        },
        methods : {
            addNote(){
                console.log('on vois les notes')
                this.$store.state.display.zone_note = 1
            }
        },
        created() {
            this.familles = this.zone.familles
            this.group = "zone-"+this.zone.id
            this.name =  "titre zone "+this.zone.id
        },
       
    };
</script>

<style lang="scss" scoped>
@import '../devis.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/pages/ui-feather.scss';
</style>
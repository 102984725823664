<template>
    <div>
       <div class='devis-mapping'>
            Mapping
            <ul class="wrap-zonning" >
                <li class="zoning" 
                    v-for="( zone  ) in $store.state.zones " 
                    v-bind:key="zone.id"  
                    v-bind:class="{ active: zone.id  == $store.state.zone_actif }"
                    @click="setActiveZone( zone.id )" 
                    >
                    <div>{{ zone.name }}</div>
                    <ul>
                        <li v-for="( famille  ) in zone.familles " >{{ famille.name }}  </li>
                    </ul>
                </li>
            </ul>
            <a class="btn-light"  @click="addZone()"  >Ajouter une zone </a>
        </div>
    </div>
</template>

<script>
import  Vuex  from 'vuex';
export default {
    mounted() {
        console.log('MAP mounted.')
    },
    data(){
        return {
            visible : this.$store.state.toggleCatalogue,
            zones : this.$store.state.zones ,
            zone_actif : this.$store.state.zone_actif , 
            nbResult : 1 , 
            newHeight : 0 , 
        }
    },
    methods: {
        setActiveZone(id){
            this.$store.commit('CHANGE_ZONE' , id)
        },
        search(){
            
        },
        addZone(){
            console.log('AddZone')
            this.$store.commit("ADD_ZONE")
        }
    }
};
</script>

<style>


</style>
